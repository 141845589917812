import React from 'react'
import './Footer.scss'


function Footer() {
  return (
    <div className='footer'>
      <div className='footer_container'>

          <p>Copyright Baner Productions</p>
          <>Latest Update 06/02/2025</>
      </div>
    
    </div>
  )
}

export default Footer
